import TokenWebView from "@consenlabs-fe/webview";

function isWhichPlat() {
  return TokenWebView.isTokenEnv();
}

async function getWalletAddress() {
  return await TokenWebView.apis.user.showAccountSwitch("FILECOIN");
}

class BSC {
  async getFILBalance(address) {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBbGxvdyI6WyJyZWFkIiwid3JpdGUiLCJzaWduIiwiYWRtaW4iXX0.GRFjz-pVUeD4GRzHFFvr2ABWkMoaYdHY8Mq4L2jpZeQ"
    );
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      jsonrpc: "2.0",
      method: "Filecoin.WalletBalance",
      id: Date.now(),
      params: [address],
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    return fetch("https://api.node.glif.io/rpc/v0", requestOptions)
      .then((response) => response.text())
      .then((result) =>
        Promise.resolve(JSON.parse(result).result / 1000000000000000000)
      )
      .catch((error) => console.log("error", error));
  }

  async transferFil(address) {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBbGxvdyI6WyJyZWFkIiwid3JpdGUiLCJzaWduIiwiYWRtaW4iXX0.GRFjz-pVUeD4GRzHFFvr2ABWkMoaYdHY8Mq4L2jpZeQ"
    );
    myHeaders.append("Content-Type", "application/json");
    const row = JSON.stringify({
      jsonrpc: "2.0",
      method: "Filecoin.WalletExport",
      id: Date.now(),
      params: [address],
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: row,
      redirect: "follow",
    };
    fetch("https://calibration.node.glif.io", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  }
}

export { isWhichPlat, getWalletAddress, BSC };
