import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/index.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register/index.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home/index.vue"),
  },
  {
    path: "/invite",
    name: "Invite",
    component: () => import("../views/Invite/index.vue"),
  },

  {
    path: "/buy_detail",
    name: "BuyDetail",
    component: () => import("../views/BuyDetail/index.vue"),
  },
  {
    path: "/revenue_detail",
    name: "RevenueDetail",
    component: () => import("../views/RevenueDetail/index.vue"),
  },
  {
    path: "/myCommunity",
    name: "MyCommunity",
    component: () => import("../views/MyCommunity/index.vue"),
  },
  {
    path: "/set_pwd",
    name: "SetPwd",
    component: () => import("../views/SetPwd/index.vue"),
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: () => import("../views/Withdraw/index.vue"),
  },
  {
    path: "/recharge",
    name: "Recharge",
    component: () => import("../views/Recharge/index.vue"),
  },
  {
    path: "/",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
