const logoImg = require("../assets/images/logo.png");
// const testAddress = "0x4b41dddd70fb05420F32ab17Ec516ce9F28Ef5B5";
const testAddress =
  process.env.NODE_ENV === "development"
    ? "f1cmar4cfkaxht7gk4qneru4fhv2jb55sfgb2aevq"
    : "";
const buyAddress =
  process.env.NODE_ENV === "development"
    ? "f1lvkhm3zjbcjcp3w6fnzvr4kocvfqktgvdglahpy"
    : "f1lvkhm3zjbcjcp3w6fnzvr4kocvfqktgvdglahpy";
const bnbLimit = 0.0003;
export { logoImg, testAddress, bnbLimit, buyAddress };
// http://192.168.0.109:8080/login?code=KFRYDI&time=1635003383647
